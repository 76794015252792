export const successGifs = [
    "babyyoda.gif",
    "bob.gif",
    "carlton.gif",
    "cerdo.gif",
    "elmo.gif",
    "friends.gif",
    "gnomo.gif",
    "homer.gif",
    "homer2.gif",
    "homer3.gif",
    "jonah.gif",
    "kid.gif",
    "loveactually.gif",
    "newgirl.gif",
    "office.gif",
    "office2.gif",
    "office3.gif",
    "office4.gif",
    "office5.gif",
    "office6.gif",
    "office7.gif",
    "padresforzosos.gif",
    "pitchperfect.gif",
    "thedude.gif",
    "tiaras.gif",
    "unclephil.gif",
    "AuronplaySi1.gif",
    "BobesponjaSi1.gif",
    "BobesponjaSi2.gif",
    "BobesponjaSi3.gif",
    "CaballosSi.gif",
    "IbaiSi1.gif",
    "IbaiSi2.gif",
    "MinionSi1.gif",
    "MinionSi2.gif",
    "RubiusSi1.gif",
]

export const failGifs = [
    "andy.gif",
    "leo.gif",
    "luke.gif",
    "officedwight.gif",
    "oficina.gif",
    "parks.gif",
    "rage.gif",
    "rage2.gif",
    "ragebw.gif",
    "reina.gif",
    "rpg.gif",
    "tabla.gif",
    "tyrabanks.gif",
    "friendsrage.gif",
    "friendsinfo.gif",
    "janice.gif",
    "joey.gif",
    "joey2.gif",
    "joey3.gif",
    "joey4.gif",
    "ross.gif",
    "rachelloser.gif",
    "AuronplayNo1.gif",
    "AuronplayNo2.gif",
    "AuronplayNo3.gif",
    "FuriosoNo.gif",
    "IbaiNo1.gif",
    "LiloNo.gif",
    "NinaNo.gif",
]