export const palabras = [
    "abran",
    "abras",
    "abría",
    "acoja",
    "acojo",
    "acres",
    "actúo",
    "aguda",
    "agudo",
    "alojó",
    "amina",
    "andas",
    "andes",
    "anima",
    "apoda",
    "apodo",
    "arces",
    "arcos",
    "ardan",
    "ardas",
    "ardes",
    "arias",
    "arios",
    "arpas",
    "Aruba",
    "aseas",
    "aseos",
    "asiré",
    "asolo",
    "aséis",
    "asían",
    "asías",
    "atajo",
    "avale",
    "avara",
    "aérea",
    "balas",
    "bares",
    "bates",
    "bayas",
    "bebed",
    "bebes",
    "besen",
    "beses",
    "besos",
    "betas",
    "bonos",
    "borre",
    "botad",
    "botan",
    "botas",
    "boten",
    "botes",
    "buses",
    "cajas",
    "callo",
    "canas",
    "canes",
    "cansa",
    "capas",
    "capes",
    "caros",
    "casan",
    "casas",
    "casca",
    "Catar",
    "cause",
    "cavas",
    "cavos",
    "cazan",
    "cazas",
    "caída",
    "caído",
    "ceded",
    "cedes",
    "cedés",
    "celes",
    "cenas",
    "cepas",
    "ceras",
    "cerdo",
    "ceros",
    "chefs",
    "Chile",
    "China",
    "chocó",
    "ciega",
    "cines",
    "clubs",
    "coces",
    "cocés",
    "codeo",
    "coged",
    "cogen",
    "coger",
    "coges",
    "cogés",
    "cogía",
    "cojas",
    "cojos",
    "cojás",
    "colas",
    "coles",
    "colón",
    "coman",
    "comed",
    "conos",
    "copas",
    "copás",
    "coral",
    "coras",
    "Corea",
    "corea",
    "coreá",
    "corra",
    "corre",
    "corré",
    "cosas",
    "coses",
    "creas",
    "crees",
    "creés",
    "croar",
    "cruje",
    "cubas",
    "cucos",
    "cuida",
    "cuide",
    "cuidá",
    "cuidé",
    "culos",
    "cunas",
    "curdo",
    "curio",
    "datos",
    "debas",
    "debed",
    "dedos",
    "densa",
    "diles",
    "doman",
    "domes",
    "donan",
    "donas",
    "dores",
    "dotas",
    "dotes",
    "Dubái",
    "dudan",
    "dunas",
    "echas",
    "eches",
    "edita",
    "eleve",
    "ellos",
    "enoja",
    "enojo",
    "entes",
    "envío",
    "erizo",
    "errar",
    "erres",
    "espiá",
    "estad",
    "estas",
    "euros",
    "evita",
    "falle",
    "falos",
    "ferio",
    "fetos",
    "forcé",
    "frías",
    "fugas",
    "gafas",
    "Gales",
    "galos",
    "gases",
    "gatos",
    "genes",
    "gimen",
    "gimes",
    "godas",
    "godos",
    "goles",
    "gorra",
    "grabe",
    "habrá",
    "habré",
    "había",
    "hacen",
    "haces",
    "hacés",
    "hacía",
    "hagan",
    "hagas",
    "hagás",
    "Haití",
    "halan",
    "halas",
    "hallo",
    "hallá",
    "halás",
    "harte",
    "harán",
    "harás",
    "haría",
    "hayan",
    "hayas",
    "hayás",
    "heces",
    "hecha",
    "hecho",
    "hemos",
    "hiero",
    "hijas",
    "hilan",
    "hilos",
    "hinca",
    "hoces",
    "hojas",
    "hunas",
    "hunos",
    "hurgo",
    "husos",
    "ibais",
    "India",
    "iréis",
    "irían",
    "irías",
    "jalón",
    "Japón",
    "jerga",
    "jugos",
    "labia",
    "labre",
    "lacra",
    "lados",
    "lagos",
    "lamen",
    "lapas",
    "larga",
    "latas",
    "lazos",
    "legas",
    "lejos",
    "leyes",
    "libró",
    "limas",
    "liras",
    "lises",
    "locas",
    "locos",
    "losas",
    "luces",
    "luche",
    "lucho",
    "lusas",
    "lusos",
    "maceo",
    "malas",
    "malos",
    "Malta",
    "mamas",
    "manda",
    "manos",
    "mapas",
    "mares",
    "mases",
    "matas",
    "mayas",
    "mazos",
    "mecen",
    "meces",
    "menús",
    "mesas",
    "mesen",
    "meses",
    "mesás",
    "mesés",
    "metas",
    "metes",
    "metás",
    "mezas",
    "mezás",
    "midas",
    "midás",
    "migro",
    "mines",
    "minés",
    "miras",
    "miren",
    "mires",
    "mirás",
    "misad",
    "mista",
    "mistá",
    "mitos",
    "modos",
    "mojar",
    "mojas",
    "mojos",
    "mojás",
    "molas",
    "moles",
    "molás",
    "monas",
    "monos",
    "moras",
    "moros",
    "morro",
    "morás",
    "moved",
    "mozas",
    "mudas",
    "mueve",
    "mulas",
    "nabos",
    "nadas",
    "nades",
    "nadés",
    "naves",
    "nazis",
    "necia",
    "Nepal",
    "nomos",
    "nubes",
    "nucas",
    "nudas",
    "nudos",
    "nulas",
    "nulos",
    "Níger",
    "obras",
    "ollas",
    "ondas",
    "onzas",
    "opera",
    "orcas",
    "orden",
    "ornan",
    "oseas",
    "ovulo",
    "pacas",
    "pacen",
    "pacés",
    "pajas",
    "pajes",
    "palas",
    "palos",
    "palpa",
    "panes",
    "paras",
    "parda",
    "paren",
    "pares",
    "parta",
    "parís",
    "pasen",
    "pases",
    "pasés",
    "patos",
    "pecas",
    "peces",
    "pedos",
    "pegas",
    "pegue",
    "penas",
    "pensé",
    "peras",
    "peros",
    "pesad",
    "pesas",
    "pesen",
    "peses",
    "pesos",
    "petas",
    "pican",
    "pidas",
    "pides",
    "pines",
    "pipas",
    "pisad",
    "pises",
    "plena",
    "pleno",
    "pocas",
    "pocos",
    "podas",
    "poded",
    "poder",
    "podrá",
    "podré",
    "podás",
    "podés",
    "podía",
    "poned",
    "ponen",
    "poner",
    "pones",
    "ponga",
    "ponés",
    "ponía",
    "posee",
    "posen",
    "poses",
    "poseé",
    "posos",
    "posés",
    "potos",
    "pozos",
    "prado",
    "pueda",
    "puede",
    "puedo",
    "pulen",
    "pumas",
    "quise",
    "quiso",
    "Quito",
    "quito",
    "ramos",
    "rasco",
    "raspá",
    "ratas",
    "ratos",
    "reces",
    "redes",
    "rejas",
    "rento",
    "reses",
    "reste",
    "retes",
    "reyes",
    "rezan",
    "rezas",
    "reúma",
    "rices",
    "rifas",
    "rimas",
    "rimes",
    "rimás",
    "rocas",
    "rojas",
    "rojos",
    "rotos",
    "runas",
    "rusas",
    "Rusia",
    "rusos",
    "sabia",
    "sabía",
    "sacan",
    "sacas",
    "sajón",
    "salta",
    "saltá",
    "salva",
    "Samoa",
    "sanad",
    "sanas",
    "sanás",
    "sapos",
    "saudí",
    "sedes",
    "sedés",
    "sepas",
    "seres",
    "seria",
    "seriá",
    "serán",
    "serás",
    "sería",
    "setas",
    "siega",
    "siego",
    "sigan",
    "Siria",
    "siria",
    "siseo",
    "soban",
    "sobas",
    "sobes",
    "sobés",
    "soles",
    "solés",
    "sonad",
    "sonar",
    "sonás",
    "subas",
    "subes",
    "sucia",
    "sudan",
    "sudar",
    "sudas",
    "suela",
    "suena",
    "suene",
    "sueno",
    "Suiza",
    "suiza",
    "supón",
    "sushi",
    "súper",
    "tacos",
    "tapas",
    "tapes",
    "tejas",
    "temas",
    "temen",
    "temes",
    "temás",
    "temés",
    "tened",
    "tenga",
    "tengo",
    "tenia",
    "tenés",
    "tenía",
    "terse",
    "tersé",
    "testá",
    "tetar",
    "tetas",
    "tetás",
    "Texas",
    "tiene",
    "titán",
    "tomad",
    "toman",
    "tomas",
    "tomes",
    "tomás",
    "Tonga",
    "tonos",
    "topos",
    "torpe",
    "tosed",
    "traed",
    "traen",
    "traer",
    "traes",
    "trajo",
    "traés",
    "traía",
    "tólar",
    "Túnez",
    "untes",
    "urdas",
    "urnas",
    "usaos",
    "usate",
    "uséis",
    "vacan",
    "vacas",
    "vagos",
    "vamos",
    "vasca",
    "vasta",
    "vates",
    "vayan",
    "vayas",
    "vayás",
    "veces",
    "vecés",
    "vedas",
    "veles",
    "velos",
    "velés",
    "vemos",
    "verás",
    "vetas",
    "veían",
    "veías",
    "viera",
    "vigas",
    "vinos",
    "vivás",
    "volar",
    "votad",
    "votan",
    "votas",
    "voten",
    "votes",
    "votos",
    "votás",
    "votés",
    "vídeo",
    "wones",
    "Yemen",
    "yendo",
    "yenes",
    "yesca",
    "yogur",
    "yugos",
    "zares",
    "zetas",
    "zonas",
    "zorro",
    "zulús",
    "zurda",
    "zurdo",
];